define("ember-svg-jar/inlined/long-arrow-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M1728 893q0 14-10 24l-384 354q-16 14-35 6-19-9-19-29v-224H32q-14 0-23-9t-9-23V800q0-14 9-23t23-9h1248V544q0-21 19-29t35 5l384 350q10 10 10 23z\"/>",
    "attrs": {
      "width": "1792",
      "height": "1792",
      "viewBox": "0 0 1792 1792",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});