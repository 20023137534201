define("ember-svg-jar/inlined/cloud", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M1984 1152q0 159-112.5 271.5T1600 1536H512q-185 0-316.5-131.5T64 1088q0-132 71-241.5T322 683q-2-28-2-43 0-212 150-362t362-150q158 0 286.5 88T1306 446q70-62 166-62 106 0 181 75t75 181q0 75-41 138 129 30 213 134.5t84 239.5z\"/>",
    "attrs": {
      "width": "2048",
      "height": "1792",
      "viewBox": "0 0 2048 1792",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});