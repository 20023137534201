define("ember-svg-jar/inlined/wrench", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M448 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm644-420l-682 682q-37 37-90 37-52 0-91-37l-106-108q-38-36-38-90 0-53 38-91l681-681q39 98 114.5 173.5T1092 1052zm634-435q0 39-23 106-47 134-164.5 217.5T1280 1024q-185 0-316.5-131.5T832 576t131.5-316.5T1280 128q58 0 121.5 16.5T1509 191q16 11 16 28t-16 28l-293 169v224l193 107q5-3 79-48.5t135.5-81T1694 582q15 0 23.5 10t8.5 25z\"/>",
    "attrs": {
      "width": "1792",
      "height": "1792",
      "viewBox": "0 0 1792 1792",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});