define("ember-svg-jar/inlined/sign-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M704 1440q0 4 1 20t.5 26.5-3 23.5-10 19.5-20.5 6.5H352q-119 0-203.5-84.5T64 1248V544q0-119 84.5-203.5T352 256h320q13 0 22.5 9.5T704 288q0 4 1 20t.5 26.5-3 23.5-10 19.5T672 384H352q-66 0-113 47t-47 113v704q0 66 47 113t113 47h312l11.5 1 11.5 3 8 5.5 7 9 2 13.5zm928-544q0 26-19 45l-544 544q-19 19-45 19t-45-19-19-45v-288H512q-26 0-45-19t-19-45V704q0-26 19-45t45-19h448V352q0-26 19-45t45-19 45 19l544 544q19 19 19 45z\"/>",
    "attrs": {
      "width": "1792",
      "height": "1792",
      "viewBox": "0 0 1792 1792",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});