define("tracked-toolbox/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.localCopy = localCopy;
  _exports.trackedReset = trackedReset;
  _exports.cached = cached;
  _exports.dedupeTracked = dedupeTracked;

  var _dec, _class, _descriptor;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  var Meta = (_dec = Ember._tracked, (_class = function Meta() {
    _classCallCheck(this, Meta);

    _defineProperty(this, "prevRemote", void 0);

    _defineProperty(this, "peek", void 0);

    _initializerDefineProperty(this, "value", _descriptor, this);
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "value", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));

  function getOrCreateMeta(instance, metas, initializer) {
    var meta = metas.get(instance);

    if (meta === undefined) {
      meta = new Meta();
      metas.set(instance, meta);
      meta.value = meta.peek = typeof initializer === 'function' ? initializer.call(instance) : initializer;
    }

    return meta;
  }

  function localCopy(memo, initializer) {
    (false && !(typeof memo === 'string' || typeof memo === 'function') && Ember.assert("@localCopy() must be given a memo path or memo function as its first argument, received `".concat(String(memo), "`"), typeof memo === 'string' || typeof memo === 'function'));
    (false && !(typeof memo !== 'function') && Ember.deprecate('Using a memoization function with @localCopy has been deprecated. Consider using @trackedReset instead.', typeof memo !== 'function', {
      id: 'local-copy-memo-fn',
      for: 'tracked-toolbox',
      until: '2.0.0'
    }));
    var metas = new WeakMap();
    return function (_prototype, key) {
      var memoFn = typeof memo === 'function' ? function (obj, last) {
        return memo.call(obj, obj, key, last);
      } : function (obj) {
        return Ember.get(obj, memo);
      };
      return {
        get: function get() {
          var meta = getOrCreateMeta(this, metas, initializer);
          var prevRemote = meta.prevRemote;
          var incomingValue = memoFn(this, prevRemote);

          if (prevRemote !== incomingValue) {
            // If the incoming value is not the same as the previous incoming value,
            // update the local value to match the new incoming value, and update
            // the previous incoming value.
            meta.value = meta.prevRemote = incomingValue;
          }

          return meta.value;
        },
        set: function set(value) {
          if (!metas.has(this)) {
            var meta = getOrCreateMeta(this, metas, initializer);
            meta.prevRemote = memoFn(this);
            meta.value = value;
            return;
          }

          getOrCreateMeta(this, metas, initializer).value = value;
        }
      };
    };
  }

  function trackedReset(memoOrConfig) {
    (false && !(typeof memoOrConfig === 'string' || typeof memoOrConfig === 'function' || _typeof(memoOrConfig) === 'object' && memoOrConfig !== null && memoOrConfig.memo !== undefined) && Ember.assert("@trackedReset() must be given a memo path, a memo function, or config object with a memo path or function as its first argument, received `".concat(String(memoOrConfig), "`"), typeof memoOrConfig === 'string' || typeof memoOrConfig === 'function' || _typeof(memoOrConfig) === 'object' && memoOrConfig !== null && memoOrConfig.memo !== undefined));
    var metas = new WeakMap();
    return function (_prototype, key, desc) {
      var _desc$initializer;

      var memo, update;
      var initializer = (_desc$initializer = desc.initializer) !== null && _desc$initializer !== void 0 ? _desc$initializer : function () {
        return undefined;
      };

      if (_typeof(memoOrConfig) === 'object') {
        var _memoOrConfig$update;

        memo = memoOrConfig.memo;
        update = (_memoOrConfig$update = memoOrConfig.update) !== null && _memoOrConfig$update !== void 0 ? _memoOrConfig$update : initializer;
      } else {
        memo = memoOrConfig;
        update = initializer;
      }

      var memoFn = typeof memo === 'function' ? function (obj, last) {
        return memo.call(obj, obj, key, last);
      } : function (obj) {
        return Ember.get(obj, memo);
      };
      return {
        get: function get() {
          var meta = getOrCreateMeta(this, metas, initializer);
          var prevRemote = meta.prevRemote;
          var incomingValue = memoFn(this, prevRemote);

          if (incomingValue !== prevRemote) {
            meta.prevRemote = incomingValue;
            meta.value = meta.peek = update.call(this, this, key, meta.peek);
          }

          return meta.value;
        },
        set: function set(value) {
          getOrCreateMeta(this, metas, initializer).value = value;
        }
      };
    };
  }

  function cached(target, key, value) {
    (false && !(value && value.get) && Ember.assert('@cached can only be used on getters', value && value.get));
    var _get = value.get,
        set = value.set;
    var caches = new WeakMap();
    return {
      get: function get() {
        var cache = caches.get(this);

        if (cache === undefined) {
          cache = Ember._createCache(_get.bind(this));
          caches.set(this, cache);
        }

        return Ember._cacheGetValue(cache);
      },
      set: set
    };
  }

  function dedupeTracked(target, key, desc) {
    var initializer = desc.initializer;

    var _Ember$_tracked = Ember._tracked(target, key, desc),
        _get2 = _Ember$_tracked.get,
        _set = _Ember$_tracked.set;

    var values = new WeakMap();
    return {
      get: function get() {
        if (!values.has(this)) {
          var value = initializer === null || initializer === void 0 ? void 0 : initializer.call(this);
          values.set(this, value);

          _set.call(this, value);
        }

        return _get2.call(this);
      },
      set: function set(value) {
        if (!values.has(this) || value !== values.get(this)) {
          values.set(this, value);

          _set.call(this, value);
        }
      }
    };
  }
});