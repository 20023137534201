define("ember-svg-jar/inlined/trophy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M522 883q-74-162-74-371H192v96q0 78 94.5 162T522 883zm1078-275v-96h-256q0 209-74 371 141-29 235.5-113t94.5-162zm128-128v128q0 71-41.5 143t-112 130-173 97.5T1186 1023q-42 54-95 95-38 34-52.5 72.5T1024 1280q0 54 30.5 91t97.5 37q75 0 133.5 45.5T1344 1568v64q0 14-9 23t-23 9H480q-14 0-23-9t-9-23v-64q0-69 58.5-114.5T640 1408q67 0 97.5-37t30.5-91q0-51-14.5-89.5T701 1118q-53-41-95-95-113-5-215.5-44.5t-173-97.5-112-130T64 608V480q0-40 28-68t68-28h288v-96q0-66 47-113t113-47h576q66 0 113 47t47 113v96h288q40 0 68 28t28 68z\"/>",
    "attrs": {
      "width": "1792",
      "height": "1792",
      "viewBox": "0 0 1792 1792",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});