define("ember-click-outside/component", ["exports", "ember-click-outside/mixin", "ember-click-outside/utils"], function (_exports, _mixin, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_mixin.default, {
    'except-selector': Ember.computed.deprecatingAlias('exceptSelector', {
      id: 'ember-click-outside.kebab-cased-props',
      until: '2.0.0'
    }),
    action: Ember.computed.deprecatingAlias('onClickOutside', {
      id: 'ember-click-outside.action-prop',
      until: '2.0.0'
    }),
    clickOutside: function clickOutside(e) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }

      var exceptSelector = Ember.get(this, 'exceptSelector');

      if (exceptSelector && (0, _utils.closest)(e.target, exceptSelector)) {
        return;
      }

      var onClickOutside = Ember.get(this, 'onClickOutside');

      if (typeof onClickOutside === 'function') {
        onClickOutside(e);
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this._cancelOutsideListenerSetup = Ember.run.next(this, this.addClickOutsideListener);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this._cancelOutsideListenerSetup);
      this.removeClickOutsideListener();
    }
  });

  _exports.default = _default;
});