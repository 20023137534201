define("ember-svg-jar/inlined/asterisk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M1546 1050q46 26 59.5 77.5T1593 1225l-64 110q-26 46-77.5 59.5T1354 1382l-266-153v307q0 52-38 90t-90 38H832q-52 0-90-38t-38-90v-307l-266 153q-46 26-97.5 12.5T263 1335l-64-110q-26-46-12.5-97.5T246 1050l266-154-266-154q-46-26-59.5-77.5T199 567l64-110q26-46 77.5-59.5T438 410l266 153V256q0-52 38-90t90-38h128q52 0 90 38t38 90v307l266-153q46-26 97.5-12.5T1529 457l64 110q26 46 12.5 97.5T1546 742l-266 154z\"/>",
    "attrs": {
      "width": "1792",
      "height": "1792",
      "viewBox": "0 0 1792 1792",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});