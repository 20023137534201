define("ember-svg-jar/inlined/group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M657 896q-162 5-265 128H258q-82 0-138-40.5T64 865q0-353 124-353 6 0 43.5 21t97.5 42.5T448 597q67 0 133-23-5 37-5 66 0 139 81 256zm1071 637q0 120-73 189.5t-194 69.5H587q-121 0-194-69.5T320 1533q0-53 3.5-103.5t14-109T364 1212t43-97.5 62-81 85.5-53.5T666 960q10 0 43 21.5t73 48 107 48 135 21.5 135-21.5 107-48 73-48 43-21.5q61 0 111.5 20t85.5 53.5 62 81 43 97.5 26.5 108.5 14 109 3.5 103.5zM704 256q0 106-75 181t-181 75-181-75-75-181 75-181T448 0t181 75 75 181zm704 384q0 159-112.5 271.5T1024 1024 752.5 911.5 640 640t112.5-271.5T1024 256t271.5 112.5T1408 640zm576 225q0 78-56 118.5t-138 40.5h-134q-103-123-265-128 81-117 81-256 0-29-5-66 66 23 133 23 59 0 119-21.5t97.5-42.5 43.5-21q124 0 124 353zm-128-609q0 106-75 181t-181 75-181-75-75-181 75-181 181-75 181 75 75 181z\"/>",
    "attrs": {
      "width": "2048",
      "height": "1792",
      "viewBox": "0 0 2048 1792",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});