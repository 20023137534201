define("ember-svg-jar/inlined/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M1344 1472q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm256 0q0-26-19-45t-45-19-45 19-19 45 19 45 45 19 45-19 19-45zm128-224v320q0 40-28 68t-68 28H160q-40 0-68-28t-28-68v-320q0-40 28-68t68-28h427q21 56 70.5 92t110.5 36h256q61 0 110.5-36t70.5-92h427q40 0 68 28t28 68zm-325-648q-17 40-59 40h-256v448q0 26-19 45t-45 19H768q-26 0-45-19t-19-45V640H448q-42 0-59-40-17-39 14-69L851 83q18-19 45-19t45 19l448 448q31 30 14 69z\"/>",
    "attrs": {
      "width": "1792",
      "height": "1792",
      "viewBox": "0 0 1792 1792",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});