define("ember-bootstrap/utils/decorators/arg", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = arg;

  function arg(target, key, descriptor) {
    var defaultValue = descriptor.initializer ? descriptor.initializer() : undefined;
    return {
      get: function get() {
        {
          return Object.keys(this.args).includes(key) ? this.args[key] : defaultValue;
        }
      }
    };
  }
});