define("ember-svg-jar/inlined/circle-o", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"currentColor\" d=\"M896 352q-148 0-273 73T425 623t-73 273 73 273 198 198 273 73 273-73 198-198 73-273-73-273-198-198-273-73zm768 544q0 209-103 385.5T1281.5 1561 896 1664t-385.5-103T231 1281.5 128 896t103-385.5T510.5 231 896 128t385.5 103T1561 510.5 1664 896z\"/>",
    "attrs": {
      "width": "1792",
      "height": "1792",
      "viewBox": "0 0 1792 1792",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});